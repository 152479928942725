import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStyles } from "./style";
import {
  Grid,
  Button,
  MenuItem,
  Typography,
  Tabs,
  Tab,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  AppBar,
} from "@material-ui/core";
import Title from "../../Title";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import { isUndefined, isEmpty } from "lodash";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import ColoredLine from "../../ColoredLine";
import AccessGroupAccessTimesForm from "./AccessTimes";
import RemoveIcon from "@material-ui/icons/Remove";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccessGroupService from "../../../services/AccessGroupService";
import RateService from "../../../services/RateService";
import apiClient from "../../../auth/apiClient";
import AccessReaderForm from "./AccessReaders";
import moment from "moment-timezone";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import useHasPermissions from "../../../hooks/useHasPermissions";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import FPASettingsSubform from "./FPASettingsSubform";
import * as c from "../../../constants";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";
import ThresholdsPanel from "../../Panels/Thresholds";
import { useFlags } from "launchdarkly-react-client-sdk";

const accessGroupService = new AccessGroupService(apiClient);
const rateService = new RateService(apiClient);

const AccessGroupForm = ({ data, onSubmit, onDelete, onCancel, ...props }) => {
  const isEditAccessGroup = !isUndefined(data.accessGroupID);
  const classes = useStyles();
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const { textConfirmation } = useConfirmationDialog();
  const groupID = data.accessGroupID;
  const [accessHolders, setAccessHolders] = useState();
  const [thresholds, setThresholds] = useState();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { hasPermissions } = useHasPermissions();
  const accessGroupsDelete = hasPermissions(["accessgroups.delete"]);
  const [isFullTimeAccess, setIsFullTimeAccess] = useState(
    !data.admittanceRules || data.admittanceRules.length === 0 ? true : false
  );
  const [availableRates, setAvailableRates] = useState([]);
  const flexibleParkingAccount = useFeatureFlag("Flexible Parking Account");
  const sharedAccounts = useFeatureFlag("Shared Accounts");
  const isCVPSEnabled = useFeatureFlag("CVPS Dual Pairing");
  const contractNestFromFacilityFeatures = useFeatureFlag("Contract Nest");
  const { issueTicketGroups, contractNest } = useFlags();

  const [availableTypes, setAvailableTypes] = useState([
    { name: "Normal", value: c.ACCESS_GROUP_TYPE.Normal },
  ]);

  const antiPassbackTypes = [
    { name: "Hard Anti-Passback", value: c.ANTI_PASSBACK_TYPE.Hard },
    { name: "Soft Anti-Passback", value: c.ANTI_PASSBACK_TYPE.Soft },
    { name: "None", value: c.ANTI_PASSBACK_TYPE.None }
  ];

  const nestViolationTypes = [
    { name: "Hard", value: c.NEST_VIOLATION_TYPE.Hard, tooltipText: "Denies entry into a nest area or denies exit of the facility based on the Nest Time." },
    { name: "Soft", value: c.NEST_VIOLATION_TYPE.Soft, tooltipText: "Allows entry into a nest area or facility exit if the access holder exceeds the nest time, but will be reported as soft nest violation." },
  ];

  const thresholdDefaults = [{
    thresholdID: "",
    threshold: 1,
    thresholdLimit: 0,
    rateBlobID: undefined,
    current: 0,
    keepInThreshold: false
  }];

  const [groupTabValue, setGroupTabValue] = useState("accessGroupDetails");

  const handleTabChange = (event, newValue) => {
    setGroupTabValue(newValue);
  };

  const handleAvailableTypesSelect = (typeValue) => {
    if (
      !(
        typeValue === c.ACCESS_GROUP_TYPE.Normal ||
        typeValue === c.ACCESS_GROUP_TYPE["Flexible Parking Account"] ||
        typeValue === c.ACCESS_GROUP_TYPE["Shared Accounts"]
      )
    ) {
      setGroupTabValue("accessGroupDetails")
    }
  };

  useEffect(() => {
    const tmpAvailableTypes = [{ name: "Normal", value: c.ACCESS_GROUP_TYPE.Normal }];
    if (flexibleParkingAccount) {
      tmpAvailableTypes.push({ name: "Flexible Parking Account", value: c.ACCESS_GROUP_TYPE["Flexible Parking Account"] });
    }
    if (sharedAccounts) {
      tmpAvailableTypes.push({ name: "Shared Accounts", value: c.ACCESS_GROUP_TYPE["Shared Accounts"] });
    }
    if (isCVPSEnabled) {
      tmpAvailableTypes.push({ name: "Valet Pairing", value: c.ACCESS_GROUP_TYPE["Valet Pairing"] });
    }
    if (issueTicketGroups) {
      tmpAvailableTypes.push({ name: "Issue Ticket", value: c.ACCESS_GROUP_TYPE["Issue Ticket"] });
    }
    setAvailableTypes(tmpAvailableTypes);
  }, [flexibleParkingAccount, sharedAccounts, isCVPSEnabled, issueTicketGroups]);

  useEffect(() => {
    if (!isUndefined(groupID)) fetchAssignedAccessHolders(groupID);
    if (!isUndefined(scopeAwareFacilityID)) fetchAvailableRates(scopeAwareFacilityID);
    if (!isUndefined(groupID) && sharedAccounts) {
      fetchThresholdData(groupID);
    } else {
      setThresholds(thresholdDefaults);
    }
  }, [groupID, sharedAccounts, scopeAwareFacilityID]);

  const fetchAssignedAccessHolders = useCallback(
    async (groupID) => {
      let response;
      try {
        response = await accessGroupService.GetAssignedAccessHolders(groupID);
      } catch {
        enqueueSnackbar("Failed to retrieve assigned access holders", {
          variant: "error",
          tag: "accessHolderFetchError",
        });
      }
      setAccessHolders(response?.data);
    },
    [accessGroupService.GetAssignedAccessHolders, groupID]
  );

  const fetchAvailableRates = useCallback(
    async (entityid) => {
      rateService
        .getAvailableRates(entityid)
        .then((result) => {
          if (result !== null) {
            setAvailableRates(result.data.sort((x, y) => x.name.localeCompare(y.name)));
          } else {
            enqueueSnackbar("No rates exist for this entity", {
              variant: "error",
              tag: "NoRatesExistForThisEntity",
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar("Unable to retrieve rates for this entity", {
            variant: "error",
            tag: "FailedToFindRatesForEntity",
          });
        });
    },
    [rateService.getAvailableRates]
  );

  const fetchThresholdData = useCallback(
    async (groupID) => {
      accessGroupService
        .GetThresholds(groupID)
        .then((result) => {
          if (result !== null) {
            setThresholds(result.data);
          } else {
            setThresholds(thresholdDefaults);
          }
        })
        .catch((err) => { });
    },
    [accessGroupService.GetThresholds, groupID]
  );

  const handleDelete = async () => {
    const response = await textConfirmation({
      title: "Delete Confirmation",
      message: `There are ${accessHolders?.length} access holders with this access group assigned. If you're sure you want to delete it, please enter the access group name in the input box below.`,
      textConfirmation: data.name,
    });
    if (!response) return;

    try {
      await accessGroupService.DeleteAccessGroup(
        scopeAwareFacilityID,
        data.accessGroupID
      );
      enqueueSnackbar("Successfully deleted access group", {
        variant: "success",
      });
    } catch {
      enqueueSnackbar("Failed to delete access group", {
        variant: "error",
        tag: "accessGroupDeleteError",
      });
      return;
    }

    onDelete(data.accessGroupID);
  };

  useEffect(() => {
    const fpaFormDefaults = {
      fpaFrequencyID: c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY.Unlimited,
      endOfDay: moment().startOf("day"),
    };

    const nestFormDefaults = {
      enabled: false,
      nestTimeMinutes: 10,
      violationType: "Hard"
    };

    let formValues = {
      type: c.ACCESS_GROUP_TYPE.Normal,
      fpaSettings: fpaFormDefaults,
      nestSettings: nestFormDefaults
    };

    if (data) {
      const dataFpaTransform = data.fpaSettings
        ? {
          ...data.fpaSettings,
          fpaFrequencyID:
            c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY[
            data.fpaSettings.fpaFrequencyID
            ],
          endOfDay: data.fpaSettings.endOfDay ?? moment().startOf("day"),
        }
        : fpaFormDefaults;

      const dataNestTransform = data.nestSettings
        ? {
          ...data.nestSettings,
          enabled: data.nestSettings.enabled,
          violationType: data.nestSettings.violationType,
          nestTimeMinutes: data.nestSettings.nestTimeMinutes
        }
        : nestFormDefaults;

      formValues = {
        ...data,
        type: c.ACCESS_GROUP_TYPE[data.type],
        fpaSettings: dataFpaTransform,
        accessGroupThresholds: thresholds ?? thresholdDefaults,
        nestSettings: dataNestTransform
      };
    }

    reset(formValues);
  }, [data, thresholds]);

  const ThresholdSchema =
    Yup.object().shape({
      rateBlobID: Yup.string().oneOf([null,
        ...availableRates?.map((rate) => {
          return rate.rateBlobID;
        })]).nullable(true),
      keepInThreshold: Yup.boolean(),
      thresholdLimit: Yup.number()
        .required("Required")
        .min(1, "Must be at least 1")
        .typeError("Must be a number")
        .integer("Must be a whole number")
    });

  const FPASchema = Yup.object().shape({
    denyEntry: Yup.boolean(),
    endOfDay: Yup.date().when("fpaFrequencyID", {
      is: (value) =>
        value === c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY.ChargeOncePerDay ||
        value === c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY.ChargeUntilDailyMax,
      then: Yup.date().required("Required"),
      otherwise: Yup.date(),
    }),
    fpaFrequencyID: Yup.number().oneOf(
      Object.values(c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY).map((opt) => {
        return opt;
      })
    ),
    maxFee: Yup.number().when(["fpaFrequencyID", "denyEntry"], {
      is: (fpaFrequencyID, denyEntry) =>
        fpaFrequencyID === c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY.ChargeUntil24HourMax ||
        fpaFrequencyID === c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY.ChargeUntilDailyMax ||
        denyEntry,
      then: Yup.number()
        .required("Must be a positive number")
        .min(0.01, "Must be a positive number")
        .typeError("Must be a positive number")
      ,
      otherwise: Yup.number().nullable().typeError(""),
    }),
  });

  const NestSettingsSchema = Yup.object().shape({
    enabled: Yup.boolean().required("Required"),
    violationType: Yup.string()
      .trim("No leading or trailing whitespaces...")
      .when("enabled", {
        is: true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().nullable(),
      }),
    nestTimeMinutes: Yup.number()
      .when("enabled", {
        is: true,
        then: Yup.number()
          .required("Required")
          .typeError("Must be a number")
          .integer("Must be a whole number")
          .min(1, "Must be a positive number")
          .max(600, "Too high"),
        otherwise: Yup.number().nullable(),
      }),
  });

  const AccessGroupSchema = Yup.object().shape({
    name: Yup.string()
      .trim("No leading or trailing whitespaces...")
      .required("Required"),
    type: Yup.number()
      .oneOf(
        Object.values(c.ACCESS_GROUP_TYPE).map((opt) => {
          return opt;
        })
      )
      .required("Required"),
    admittanceRules: Yup.array()
      .of(
        Yup.object().shape({
          startTime: Yup.string().trim("No leading or trailing whitespaces..."),
          endTime: Yup.string().trim("No leading or trailing whitespaces..."),
          applicableDay: Yup.string().trim(
            "No leading or trailing whitespaces..."
          ),
        })
      )
      .when("isFullTimeAccess", {
        is: "false",
        then: Yup.array(
          Yup.object().shape({
            startTime: Yup.string()
              .trim("No leading or trailing whitespaces...")
              .required("Required"),
            endTime: Yup.string()
              .trim("No leading or trailing whitespaces...")
              .required("Required")
              .test(
                "is-greater",
                "End time cannot be before start time",
                function (value) {
                  if (!value) return false;
                  let { startTime } = this.parent;
                  startTime = moment(moment(startTime));
                  const endTime = moment(value);
                  return (
                    endTime.minutes() + endTime.hours() * 60 >
                    startTime.minutes() + startTime.hours() * 60
                  );
                }
              ),
            applicableDay: Yup.string().required("Required"),
          })
        )
          .min(1)
          .required("At least one access time is required"),
        otherwise: Yup.array().nullable(),
      }),
    isFullTimeAccess: Yup.boolean(),
    fpaSettings: Yup.object().when("type", {
      is: (type) => type === c.ACCESS_GROUP_TYPE["Flexible Parking Account"] || type === c.ACCESS_GROUP_TYPE["Issue Ticket"],
      then: FPASchema.concat(Yup.object().shape({
        rateBlobID: Yup.string().oneOf(
          availableRates.map((rate) => {
            return rate.rateBlobID;
          })
        ).required("Required")
      }),
      ),
      otherwise: Yup.object().when("type", {
        is: (type) => type === c.ACCESS_GROUP_TYPE["Shared Accounts"],
        then: FPASchema,
        otherwise: Yup.object().nullable(),
      }),
    }),
    accessGroupThresholds: Yup.array().when("type", {
      is: (type) => type === c.ACCESS_GROUP_TYPE["Shared Accounts"],
      then:
        Yup.array().of(ThresholdSchema),
      otherwise:
        Yup.array().nullable()
    }),
    nestSettings: NestSettingsSchema
  });

  const createOrUpdateAccessGroup = async (values) => {
    const {
      admittanceRules,
      attributes,
      name,
      type,
      accessReaderIDs,
      fpaSettings,
      accessGroupThresholds,
      nestSettings
    } = values;

    let accessGroup = {
      name,
      type,
      attributes,
      admittanceRules: isFullTimeAccess ? [] : admittanceRules,
      accessReaderIDs,
      nestSettings
    };

    if (type !== c.ACCESS_GROUP_TYPE["Normal"] && type !== c.ACCESS_GROUP_TYPE["Valet Pairing"]) {
      const endOfDay =
        fpaSettings.fpaFrequencyID ===
          c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY.ChargeOncePerDay ||
          fpaSettings.fpaFrequencyID ===
          c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY.ChargeUntilDailyMax
          ? moment(fpaSettings.endOfDay)
            .utcOffset(0, true)
            .format()
          : null;

      accessGroup.fpaSettings = {
        fpaFrequencyID: fpaSettings.fpaFrequencyID,
        denyEntry:
          fpaSettings.denyEntry === null ? false : fpaSettings.denyEntry,
        maxFee: fpaSettings.maxFee ?? null,
        rateBlobID: fpaSettings.rateBlobID,
        endOfDay: endOfDay,
      };
    }

    if (type === c.ACCESS_GROUP_TYPE["Shared Accounts"]) {
      accessGroup.accessGroupThresholds = accessGroupThresholds;
      accessGroup.fpaSettings.fpaFrequencyID = c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY.Unlimited;
    }

    if (type === c.ACCESS_GROUP_TYPE["Valet Pairing"] || type === c.ACCESS_GROUP_TYPE["Issue Ticket"]) {
      accessGroup.attributes.antiPassbackType = c.ANTI_PASSBACK_TYPE.None;
    }

    if (type === c.ACCESS_GROUP_TYPE["Issue Ticket"]) {
      accessGroup.fpaSettings.fpaFrequencyID = c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY.Unlimited;
    }

    if (!isEditAccessGroup) {
      try {
        await accessGroupService.CreateAccessGroup(scopeAwareFacilityID, accessGroup);
      } catch {
        enqueueSnackbar("Failed to create access group", {
          variant: "error",
          tag: "accessGroupCreateError",
        });
        return;
      }

      enqueueSnackbar("Successfully created access group", {
        variant: "success",
      });
    } else {
      try {
        accessGroup.accessGroupID = data.accessGroupID;
        await accessGroupService.UpdateAccesGroup(
          data.accessGroupID,
          accessGroup
        );
      } catch {
        enqueueSnackbar("Failed to update access group", {
          variant: "error",
          tag: "accessGroupUpdateError",
        });
        return;
      }

      enqueueSnackbar("Successfully updated access group", {
        variant: "success",
      });
    }

    onSubmit();
  };

  const {
    watch,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(AccessGroupSchema),
    defaultValues: {
      ...data,
    },
    mode: "onChange",
  });

  const currentType = watch("type");
  const currentChargeFrequency = watch("fpaSettings.fpaFrequencyID");
  const denyEntry = watch("fpaSettings.denyEntry");

  const admittanceRulesFields = useFieldArray({
    control,
    name: "admittanceRules",
  });

  const thresholdFields = useFieldArray({
    control,
    name: "accessGroupThresholds",
  });

  const [isSubFormValid, setIsSubFormValid] = useState(true);

  const handleAccessTimeChange = (ranges) => {
    const values = getValues();
    if (
      values.admittanceRules.length === 1 &&
      isEmpty(values.admittanceRules[0])
    ) {
      setValue("admittanceRules", [ranges]);
      return;
    }

    let rules = values.admittanceRules?.slice() ?? [];
    let foundIndex;
    if (!isUndefined(ranges.accessGroupRuleID)) {
      foundIndex = rules.findIndex(
        (x) => x.accessGroupRuleID === ranges.accessGroupRuleID
      );
    } else {
      foundIndex = rules.findIndex((x) => x.identifier === ranges.identifier);
    }

    if (foundIndex >= 0) {
      // already exists
      rules[foundIndex] = ranges;
    } else {
      // new access time rules
      rules[rules.length - 1] = ranges;
    }

    setValue("admittanceRules", rules);
  };

  const handleAccessReaderChange = (deviceIDs) => {
    setValue("accessReaderIDs", deviceIDs);
  };

  const handleFormCancel = () => {
    setValue("admittanceRules", []);
    onCancel();
  };

  const handleFormValid = (isValid) => {
    setIsSubFormValid(isValid);
  };

  const formatTextField = (e) => {
    const val = e.target.value;
    setValue(e.target.name, val.trim());
  };

  const handleFullTimeAccessChange = (value) => {
    setValue("isFullTimeAccess", value);
    setIsFullTimeAccess(value);
  };

  const handleRateChange = (value) => {
    setValue("fpaSettings.rateBlobID", value);
  };

  return (
    <>
      <Grid
        container
        className={clsx("grid-container")}
        spacing={2}
        justify="space-between"
      >
        <Grid item className={clsx("grid-item")} xs={9}>
          <Title className={clsx("accessgroup-title")}>
            {isEditAccessGroup === true ? "Edit " : "Create "} Access Group
          </Title>
        </Grid>
        {isEditAccessGroup === true && accessGroupsDelete && (
          <Grid
            item
            xs={3}
            className={clsx("delete-button-grid", classes.deleteBtnGrid)}
          >
            <Button
              className={clsx("delete-accessgroup-btn")}
              startIcon={<DeleteIcon className={clsx("delete-icon")} />}
              variant="contained"
              color="secondary"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Grid>
        )}
      </Grid>
      <form
        onSubmit={handleSubmit(
          async (data) => await createOrUpdateAccessGroup(data)
        )}
        noValidate
      >
        <div className={clsx("accessgroup-formik")}>
          <div className={clsx("form-container", classes.formContainer)}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="name"
                  className={clsx("accessgroup-name", classes.flexInput)}
                  label="Name"
                  onBlur={formatTextField}
                  variant="outlined"
                  error={!!errors.name}
                  helperText={errors.name && errors.name.message}
                  inputProps={{
                    "data-testid": "accessgroup-name-input",
                  }}
                />
              )}
            />

            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <TextField
                  defaultValue={c.ACCESS_GROUP_TYPE.Normal}
                  {...field}
                  id="accessgroup-type"
                  className={clsx(
                    "accessgroup-type",
                    classes.accessGroupType,
                    classes.flexInput
                  )}
                  label="Type"
                  select
                  variant="outlined"
                  SelectProps={{
                    SelectDisplayProps: { "data-testid": "accessgroup-type" },
                  }}
                  disabled={isEditAccessGroup}
                >
                  {availableTypes.map((type, index) => (
                    <MenuItem
                      className={clsx(
                        `accessgroup-type-${type.name
                          .replace(/\s+/g, "-")
                          .toLowerCase()}`
                      )}
                      key={type.value}
                      value={type.value}
                      onClick={() => handleAvailableTypesSelect(type.value)}
                    >
                      {type.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <ColoredLine />
            <AppBar position="static" color="transparent" elevation={0}>
              <Tabs
                value={groupTabValue}
                onChange={handleTabChange}
                aria-label="Tabs"
              >
                <Tab value="accessGroupDetails" label="Access Group Details" />
                {
                  contractNest && contractNestFromFacilityFeatures &&
                  (
                    currentType == c.ACCESS_GROUP_TYPE.Normal ||
                    currentType == c.ACCESS_GROUP_TYPE["Flexible Parking Account"] ||
                    currentType == c.ACCESS_GROUP_TYPE["Shared Accounts"]
                  ) && (
                    <Tab value="nestSettings" label="Nest Settings" data-testid="nest-settings-tab" />
                  )}
              </Tabs>
              {groupTabValue === "accessGroupDetails" && (
                <Grid container>
                  <Grid
                    item
                    xs={
                      currentType !== c.ACCESS_GROUP_TYPE.Normal &&
                        currentType !== c.ACCESS_GROUP_TYPE["Valet Pairing"]
                        ? 6
                        : 12
                    }
                  >
                    <Typography
                      className={clsx("access-days-and-times")}
                      variant="h5"
                    >
                      Access Days and Times
                    </Typography>
                    <Typography
                      className={clsx(
                        "days-and-times-description",
                        classes.description
                      )}
                      variant="caption"
                    >
                      Select the days and times that apply to this access group. You
                      can choose all open times or indicate specific days and the
                      time ranges by selecting &quot;Time Restricted Access&quot;
                    </Typography>
                    <Grid
                      container
                      className={clsx(
                        "time-access-radio-group",
                        classes.timeRadioGroup
                      )}
                    >
                      <Grid item xs={12}>
                        <label
                          className={clsx("radio-24-7-label", classes.radioLabel)}
                        >
                          <input
                            className={clsx("radio-24-7", classes.radioButton)}
                            type="radio"
                            name="isFullTimeAccess"
                            value="true"
                            checked={isFullTimeAccess}
                            onChange={() => handleFullTimeAccessChange(true)}
                          />
                          24/7 Access
                        </label>
                      </Grid>
                      <Grid item xs={12}>
                        <label
                          className={clsx(
                            "time-restricted-radio-label",
                            classes.radioLabel
                          )}
                        >
                          <input
                            className={clsx(
                              "time-restricted-radio",
                              classes.radioButton
                            )}
                            type="radio"
                            name="isFullTimeAccess"
                            value="false"
                            checked={!isFullTimeAccess}
                            onChange={() => handleFullTimeAccessChange(false)}
                          />
                          Time Restricted Access
                        </label>
                      </Grid>
                    </Grid>

                    {!isFullTimeAccess && (
                      <>
                        <Button
                          className={clsx(
                            "access-time-add-btn",
                            classes.addAccessTimeBtn
                          )}
                          variant="contained"
                          color="primary"
                          onClick={() => admittanceRulesFields.append({})}
                        >
                          Add Access Time
                        </Button>
                        <div className={clsx(classes.timesForms)}>
                          <div className={clsx(classes.timesRow)}>
                            {admittanceRulesFields.fields.map((timeGroup, index) => (
                              <Accordion
                                className={clsx("accordion", `time-group-${index}`)}
                                key={timeGroup.id}
                                defaultExpanded
                              >
                                <AccordionSummary
                                  className={clsx("accordion-summary")}
                                  expandIcon={
                                    <ExpandMoreIcon
                                      className={clsx("expand-more-icon")}
                                    />
                                  }
                                >
                                  <IconButton
                                    className={clsx("remove-time-group-btn")}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => admittanceRulesFields.remove(index)}
                                  >
                                    <RemoveIcon className={clsx("remove-icon")} />
                                  </IconButton>
                                  <Typography
                                    className={clsx("timegroup-rulename")}
                                  >
                                    {timeGroup.ruleName}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                  className={clsx("accordion-details")}
                                >
                                  <AccessGroupAccessTimesForm
                                    className={clsx("accessgroup-accesstimes-form")}
                                    data={timeGroup}
                                    onChange={handleAccessTimeChange}
                                    validChanged={handleFormValid}
                                  />
                                </AccordionDetails>
                              </Accordion>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                    <ColoredLine />
                    {currentType !== c.ACCESS_GROUP_TYPE["Valet Pairing"] && (
                      <>
                        <Typography className={clsx("access-attributes")} variant="h5">
                          Access Attributes
                        </Typography>
                        <div
                          className={clsx(
                            "attributes-container",
                            classes.attributesContainer,
                            classes.attributesFlexContainer
                          )}
                        >
                          <label
                            className={clsx(
                              "enabled-checkbox-label",
                              classes.checkboxLabel
                            )}
                          >
                            <Controller
                              control={control}
                              name="attributes.enabled"
                              render={({ field }) => (
                                <input
                                  {...field}
                                  className={clsx("enabled-checkbox", classes.checkbox)}
                                  type="checkbox"
                                  checked={field.value}
                                  data-checked={field.value}
                                />
                              )}
                            />
                            Enabled
                          </label>
                          <Tooltip
                            className={clsx("group-tooltip")}
                            arrow
                            title="When Exclusive Credentials is enabled, access holders are required to exit using the same credential they entered with."
                          >
                            <label
                              className={clsx(
                                "exclusive-credentials-checkbox-label",
                                classes.checkboxLabel
                              )}
                            >
                              <Controller
                                control={control}
                                name="attributes.exclusiveCredentials"
                                render={({ field }) => (
                                  <input
                                    {...field}
                                    className={clsx(
                                      "exclusive-credentials-checkbox",
                                      classes.checkbox
                                    )}
                                    type="checkbox"
                                    checked={field.value}
                                    data-checked={field.value}
                                  />
                                )}
                              />
                              Exclusive Credentials
                            </label>
                          </Tooltip>
                          <Tooltip
                            className={clsx("group-tooltip")}
                            arrow
                            title="When Track on Dashboard is enabled, this access group will be broken out on the dashboard’s contract chart."
                          >
                            <label
                              className={clsx(
                                "track-on-dashboard-checkbox-label",
                                classes.checkboxLabel
                              )}
                            >
                              <Controller
                                control={control}
                                name="attributes.tracking"
                                render={({ field }) => (
                                  <input
                                    {...field}
                                    className={clsx(
                                      "track-on-dashboard-checkbox",
                                      classes.checkbox
                                    )}
                                    type="checkbox"
                                    checked={field.value}
                                    data-checked={field.value}
                                  />
                                )}
                              />
                              Track on Dashboard
                            </label>
                          </Tooltip>
                        </div>
                        {currentType !== c.ACCESS_GROUP_TYPE["Issue Ticket"] && (
                          <>
                            <div
                              className={clsx(
                                "attributes-container",
                                classes.attributesFlexContainer
                              )}
                            >
                              <Controller
                                name="attributes.antiPassbackType"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    defaultValue={c.ANTI_PASSBACK_TYPE.Hard}
                                    {...field}
                                    id="antipassback-type"
                                    className={clsx(
                                      "antipassback-type",
                                      classes.flexInput
                                    )}
                                    label="Anti-Passback"
                                    select
                                    fullWidth
                                    variant="outlined"
                                    SelectProps={{
                                      SelectDisplayProps: { "data-testid": "antipassback-type" },
                                    }}
                                  >
                                    {antiPassbackTypes.map((type, index) => (
                                      <MenuItem
                                        className={clsx(
                                          `antipassback-type-${type.name
                                            .replace(/\s+/g, "-")
                                            .toLowerCase()}`
                                        )}
                                        key={type.value}
                                        value={type.value}
                                      >
                                        {type.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              />
                            </div>
                          </>
                        )}
                        <ColoredLine />
                      </>
                    )}
                    <div
                      className={clsx(
                        "access-reader-section",
                        classes.accessReaderSection
                      )}
                    >
                      <Typography className={clsx("access-readers")} variant="h5">
                        Access Readers
                      </Typography>
                      <Typography
                        className={clsx(
                          "access-readers-description",
                          classes.description
                        )}
                        variant="caption"
                      >
                        Please select all readers / devices that are included in
                        this access group.
                      </Typography>
                      <AccessReaderForm
                        className={clsx("access-reader-form")}
                        data={getValues().accessReaderIDs}
                        onChange={handleAccessReaderChange}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    {currentType !== c.ACCESS_GROUP_TYPE.Normal &&
                      currentType !== c.ACCESS_GROUP_TYPE["Valet Pairing"] && (
                        <FPASettingsSubform
                          control={control}
                          errors={errors}
                          currentChargeFrequency={currentChargeFrequency}
                          availableRates={availableRates}
                          denyEntry={denyEntry}
                          currentRate={getValues("fpaSettings.rateBlobID")}
                          handleRateChange={handleRateChange}
                          currentType={currentType}
                        />
                      )}
                    {currentType ===
                      c.ACCESS_GROUP_TYPE["Shared Accounts"] &&
                      thresholds && (
                        <ThresholdsPanel
                          control={control}
                          errors={errors}
                          thresholdFields={thresholdFields}
                          availableRates={availableRates}
                          watch={watch}
                        />
                      )}
                  </Grid>
                </Grid>
              )}
              {groupTabValue === "nestSettings" && (
                <Grid container>
                  <Grid item xs={6}>
                    <div
                      className={clsx(
                        "nest-attributes-container",
                        classes.AttributesContainer,
                        classes.nestSettingsFlexContainer
                      )}
                    >
                      <label className={clsx("nestEnabledCheckboxLabel", classes.nestEnabledCheckboxLabel)}>
                        <Controller
                          control={control}
                          name="nestSettings.enabled"
                          render={({ field }) => (
                            <input
                              {...field}
                              className={clsx("nest-enabled-checkbox", classes.checkbox)}
                              type="checkbox"
                              checked={field.value}
                              data-checked={field.value}
                            />
                          )}
                        />
                        Enabled
                      </label>
                    </div>
                    <div
                      className={clsx(
                        "attributes-container",
                        classes.nestSettingsFlexContainer
                      )}
                    >
                      <Controller
                        name="nestSettings.violationType"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            defaultValue={c.NEST_VIOLATION_TYPE.Hard}
                            {...field}
                            id="nestviolation-type"
                            className={clsx(
                              "nestviolation-type",
                              classes.nestFlexInput
                            )}
                            label="Violation Type"
                            select
                            fullWidth
                            variant="outlined"
                            SelectProps={{
                              SelectDisplayProps: { "data-testid": "nestviolation-type" },
                            }}
                            disabled={!watch("nestSettings.enabled")}
                          >
                            {nestViolationTypes.map((type, index) => (
                              <MenuItem
                                className={clsx(
                                  `nestviolation-type-${type.name
                                    .replace(/\s+/g, "-")
                                    .toLowerCase()}`
                                )}
                                key={type.value}
                                value={type.value}
                              >
                                <Tooltip
                                  className={clsx("group-tooltip")}
                                  arrow
                                  title={type.tooltipText}
                                >
                                  <div>
                                    {type.name}
                                  </div>
                                </Tooltip>
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </div>

                    <div
                      className={clsx(
                        "nest-time-container",
                        classes.nestSettingsFlexContainer
                      )}
                    >
                      <Controller
                        name="nestSettings.nestTimeMinutes"
                        control={control}
                        render={({ field }) => (
                          <Tooltip
                            className={clsx("group-tooltip")}
                            arrow
                            title="The time allotted for an access holder to reach a nest area and exit the facility after leaving the nest."
                          >
                            <TextField
                              {...field}
                              id="nest-time"
                              className={clsx("nest-time", classes.nestTimeFlexInput)}
                              label="Nest Time"
                              variant="outlined"
                              error={!!errors.nestSettings?.nestTimeMinutes}
                              helperText={!!errors.nestSettings?.nestTimeMinutes && errors.nestSettings?.nestTimeMinutes?.message}
                              inputProps={{
                                "data-testid": "nest-time-input",
                              }}
                              disabled={!watch("nestSettings.enabled")}
                            />
                          </Tooltip>
                        )}
                      />
                    </div>
                    <Typography
                      className={clsx("in-minutes", classes.inMinutes)}
                    >
                      in minutes
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </AppBar>
            <Grid
              className={clsx("button-container", classes.btnContainer)}
              container
              spacing={2}
            >
              <Grid item className={clsx("save-button-grid-item")}>
                <Button
                  className={clsx("submit-btn")}
                  startIcon={<SaveIcon className={clsx("save-icon")} />}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting || !isSubFormValid}
                >
                  {isEditAccessGroup === true ? "Update" : "Create"}
                </Button>
              </Grid>
              <Grid item className={clsx("cancel-button-grid-item")}>
                <Button
                  className={clsx("cancel-btn")}
                  startIcon={<CancelIcon className={clsx("cancel-icon")} />}
                  variant="contained"
                  onClick={handleFormCancel}
                  disabled={isSubmitting === true}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </form>
    </>
  );
};

AccessGroupForm.defaultProps = {
  data: {
    attributes: {
      enabled: true,
      exclusiveCredentials: false,
      tracking: false,
      antiPassbackType: "HardAP"
    },
    admittanceRules: [],
    name: "",
    type: "Normal",
    fpaSettings: {
      fpaFrequencyID: "",
      denyEntry: false,
      endOfDay: undefined,
      maxFee: undefined,
      rateBlobID: undefined,
    },
    accessGroupThresholds: [{
      thresholdID: "",
      threshold: 1,
      thresholdLimit: 0,
      rateBlobID: null,
      current: 0,
      keepInThreshold: false
    },],
    nestSettings: {
      enabled: false,
      violationType: "Hard",
      nestTimeMinutes: 10,
    },
  },
  onCancel: () => { },
};

AccessGroupForm.propTypes = {
  data: PropTypes.shape({
    accessGroupID: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    admittanceRules: PropTypes.arrayOf(
      PropTypes.shape({
        startTime: PropTypes.string,
        endTime: PropTypes.string,
        applicableDay: PropTypes.string,
      })
    ),
    fpaSettings: PropTypes.shape({
      fpaFrequencyID: PropTypes.string,
      denyEntry: PropTypes.bool,
      endOfDay: PropTypes.string,
      maxFee: PropTypes.number,
      rateBlobID: PropTypes.string,
    }),
    accessGroupThresholds: PropTypes.arrayOf(
      PropTypes.shape({
        thresholdID: PropTypes.string,
        threshold: PropTypes.number,
        thresholdLimit: PropTypes.number,
        rateBlobID: PropTypes.string,
        current: PropTypes.number,
        keepInThreshold: PropTypes.bool
      })),
    nestSettings: PropTypes.shape({
      enabled: PropTypes.bool,
      violationType: PropTypes.string,
      nestTimeMinutes: PropTypes.number
    }),
  }),
  onCancel: PropTypes.func,
};

export default AccessGroupForm;
