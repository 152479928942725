import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  drawerContainer: {
    padding: theme.spacing(2),
  },
  buttonContainer: {
    margin: theme.spacing(2),
  },
  btnControl: {
    margin: theme.spacing(1),
  },
  tableContainer: {
    overflowY: "auto",
    overflowX: "auto",
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  DrawerTitle: {
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      textAlign: "center",
    },
  },
  table: {
    tableLayout: "fixed",
    [theme.breakpoints.down("md")]: {
      tableLayout: "auto",
    },
  },
  tableCells: {
    alignContent: "center",
    justifyContent: "center",
  },
  actionTableCell: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "200px",
    },
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  addButtonGrid: {
    height: "50px",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  errorText: {
    color: theme.palette.error.dark,
  },
}));
