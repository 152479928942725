import React, {useEffect } from "react";
import { useSelector } from "react-redux";

import FacilityGroupDisplay from "./FacilityGroupDisplay/FacilityGroupDisplay.index";
import FacilityDisplay from "./FacilityDisplay/FacilityDisplay.index";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRevenueDataContext } from "./RevenueDataProvider";
import { useRevenuePollingFlagContext } from "./useRevenueDashboardPollingContext";

export default function RevenueDashboardWidget() {

  const facilityGroupID = useSelector((state) => state.entityScope?.facilityGroupId);
  const { facilityGroups } = useFlags();
  const facilities = useSelector((state) => state.entityScope.selected) ?? [];

  const {
    loadRevenueData
  } = useRevenueDataContext();

  const {hideTimer} = useRevenuePollingFlagContext()

  useEffect(() => {
    loadRevenueData(facilities);
  }, [facilities]);

  const renderFacilityDisplay = (((facilityGroups && facilityGroupID) ) ?
    (
    <FacilityGroupDisplay />
    ) : (
    <FacilityDisplay />
    )
)

  return (
    <>
      {!hideTimer() &&
        <>
          {renderFacilityDisplay}
        </>
      }
    </>
  )
}