class SearchService {
  constructor(client) {
    this.apiClient = client;
  }

  async getSearchResults(entityIds, currentEntityId, searchTerm, cancelToken) {
    return await this.apiClient.post(`search/${currentEntityId}`, entityIds, {
      cancelToken: cancelToken,
      params: { searchTerm },
    });
  }

  /***
   * @param {string[]} scopedEntityIds Array of GUID strings of selected scopes
   */
  async getSearchResultsV2(contextEntityId, scopedEntityIds, searchTerm, searchCategory) {
    return await this.apiClient.get(`search/v2/${contextEntityId}`, {
      params: { searchTerm, searchCategory, entityIDs: scopedEntityIds }
    })
  }

  async getAccessHolder(accessholderID, facilityID) {
    return await this.apiClient.get(
      `search/${facilityID}/accessholder/${accessholderID}`,
      {
        params: { RecentActivityCount: 1 },
    });
  }

  async searchAccessHolders(contextEntityId, searchTerm, searchCategory, credentialType,
    status, mode, group, limit, offset, cancelToken) {
    return await this.apiClient.get(`accessholdersearch/${contextEntityId}`, {
      cancelToken: cancelToken,
      params: { searchTerm, searchCategory, credentialType, status, mode, group, limit, offset },
    });
  }
  
  async searchV3(entityID, searchTerm, searchCategory) {
    return this.apiClient.get(`callcentersearch/v3/${entityID}`, {
      params: { searchTerm, searchCategory }
    })
  }
}

export default SearchService;
