import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import clsx from "clsx";
import { Button, Typography, TextField, MenuItem } from "@material-ui/core";

import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { useValetArrival } from "./useValetArrival";

import apiClient from "../../../auth/apiClient";
import ValetParkingService from "../../../services/ValetParkingService";

import { FIELD_TYPE, FIELD_ID, FORM_FIELD_DETAILS } from "./ArrivalFieldConstants";

import { FindEntity } from "../../../state/slices/entities";
import { clearValetActivity } from "../../../state/slices/shiftSession/shiftSession";

const CashieredDevicePerformValetArrivalActivity = ({ classes, onCancel }) => {
    const dispatch = useDispatch();
    const enqueueSnackbar = useEnqueueSnackbar();

    const entityID = useSelector((state) => state.entities?.ContextID);
    const parentEntity = useSelector((state) => {
        const entity = FindEntity(state.entities?.EntityList ?? [], entityID);
        return FindEntity(state.entities?.EntityList ?? [], entity.parententityid);
    }, shallowEqual);

    const ticketNumber = useSelector((state) => state.shiftSession?.valetActivity?.ticketNumber);
    const notificationStyle = useSelector((state) => {
        return {
            small: state.shiftSession.smallScreen,
            toastLocation: state.shiftSession.toastLocation,
        };
    });

    const updateStateValue = (fieldID, value) => {
        let fiteredField = FORM_FIELD_DETAILS.find(field => field.fieldID === fieldID);
        if (!fiteredField) return;
        setValue(fiteredField.fieldStateName, value);
    };

    const triggerEnqueueSnackBar = (msg, variant, tag) => {
        enqueueSnackbar(msg, {
            variant: variant,
            tag: tag,
            anchorOrigin: notificationStyle.toastLocation,
            fullwidth: notificationStyle.small,
        });
    };

    const {
        arrivalTicketFields,
        guestTypes,
        rates,
        colors,
        makes,
        models,
        countries,
        states,
        parkedByEmployees,
        fetchStates,
        fetchModels,
        ticketGuestType
    } = useValetArrival(entityID, ticketNumber, parentEntity, triggerEnqueueSnackBar, updateStateValue);

    const [arrivalData] = useState({
        firstName: "",
        lastName: "",
        licensePlate: "",
        vin: "",
        email: "",
        phoneNumber: "",
        parkedSpace: "",
        guestTypeID: "",
        rateID: "",
        colorID: "",
        makeID: "",
        modelID: "",
        parkedBy: 1, //Setting default parkedByEmployee as 1 so that selected value would be unassigned
        licenseCountryID: "",
        licenseStateID: ""
    });

    const valetParkingService = new ValetParkingService(apiClient);

    //This collection contains dropdown fields .
    // Each associated with respective data source,value column names,text column names,
    // isDepedentDropdown signifying if dropdown is dependent on any other dropdown selection like State,Model
    const DROPDOWN_SOURCES = [
        { fieldID: FIELD_ID.GuestType, Source: guestTypes, TextColumn: "name", ValueColumn: "guestTypeID", isDepedentDropdown: false },
        { fieldID: FIELD_ID.Rate, Source: rates, TextColumn: "name", ValueColumn: "rateBlobID", isDepedentDropdown: false },
        { fieldID: FIELD_ID.Color, Source: colors, TextColumn: "colorName", ValueColumn: "colorID", isDepedentDropdown: false },
        { fieldID: FIELD_ID.Make, Source: makes, TextColumn: "makeName", ValueColumn: "makeID", isDepedentDropdown: false },
        { fieldID: FIELD_ID.Model, Source: models, TextColumn: "modelName", ValueColumn: "modelID", isDepedentDropdown: true, DependentText: "Select a Make" },
        { fieldID: FIELD_ID.LicenseCountry, Source: countries, TextColumn: "countryName", ValueColumn: "countryID" },
        { fieldID: FIELD_ID.LicenseState, Source: states, TextColumn: "subdivisionName", ValueColumn: "subdivisionID", isDepedentDropdown: true, DependentText: "Select a Country" },
        { fieldID: FIELD_ID.ParkedByEmployee, Source: parkedByEmployees, TextColumn: "employeeName", ValueColumn: "employeeID", isDepedentDropdown: false },
    ];

    const conditionalRequired = (isRequired, schema) => (isRequired ? schema.required("Required") : schema);
    const transformEmptyStringToNull = (value, originalValue) => (originalValue === "" ? null : value);

    const isFieldRequired = (fieldID) => {
        let field = arrivalTicketFields?.find(field => field.fieldID === fieldID);
        return field ? field.required : false;
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().trim().when("$isFirstNameRequired", conditionalRequired),
        lastName: Yup.string().trim().when("$isLastNameRequired", conditionalRequired),
        licensePlate: Yup.string().trim().when("$isLicensePlateRequired", conditionalRequired),
        vin: Yup.string().trim().when("$isVINRequired", conditionalRequired),
        email: Yup.string().email('Invalid email address').when("$isEmailRequired", conditionalRequired),
        phoneNumber: Yup.string().trim().when("$isPhoneNumberRequired", conditionalRequired),
        parkedSpace: Yup.string().trim().when("$isParkedSpaceRequired", conditionalRequired),
        guestTypeID: Yup.number().when("$isGuestTypeRequired", conditionalRequired),
        rateID: Yup.string().when("$isRateRequired", conditionalRequired),
        colorID: Yup.number().transform(transformEmptyStringToNull).nullable().typeError("Required").when("$isColorRequired", conditionalRequired),
        makeID: Yup.number().transform(transformEmptyStringToNull).nullable().typeError("Required").when("$isMakeRequired", conditionalRequired),
        modelID: Yup.number().transform(transformEmptyStringToNull).nullable().typeError("Required").when("$isModelRequired", conditionalRequired),
        licenseCountryID: Yup.number().transform(transformEmptyStringToNull).nullable().typeError("Required").when("$isLicenseCountryRequired", conditionalRequired),
        licenseStateID: Yup.number().transform(transformEmptyStringToNull).nullable().typeError("Required").when("$isLicenseStateRequired", conditionalRequired),
    });

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: { ...arrivalData },
        resolver: yupResolver(validationSchema),
        mode: "all",
        context: {
            isFirstNameRequired: isFieldRequired(FIELD_ID.FirstName),
            isLastNameRequired: isFieldRequired(FIELD_ID.LastName),
            isLicensePlateRequired: isFieldRequired(FIELD_ID.LicensePlate),
            isVINRequired: isFieldRequired(FIELD_ID.VIN),
            isEmailRequired: isFieldRequired(FIELD_ID.Email),
            isPhoneNumberRequired: isFieldRequired(FIELD_ID.PhoneNumber),
            isParkedSpaceRequired: isFieldRequired(FIELD_ID.ParkedSpace),
            isGuestTypeRequired: isFieldRequired(FIELD_ID.GuestType),
            isRateRequired: isFieldRequired(FIELD_ID.Rate),
            isColorRequired: isFieldRequired(FIELD_ID.Color),
            isMakeRequired: isFieldRequired(FIELD_ID.Make),
            isModelRequired: isFieldRequired(FIELD_ID.Model),
            isLicenseCountryRequired: isFieldRequired(FIELD_ID.LicenseCountry),
            isLicenseStateRequired: isFieldRequired(FIELD_ID.LicenseState),
        }
    });

    const selectedGuestType = useWatch({ control, name: 'guestTypeID' });
    const selectedVehicleMake = useWatch({ control, name: 'makeID' });
    const selectedCountry = useWatch({ control, name: 'licenseCountryID' });

    //When GuestType is changed then Rate would be changed with the newly selected guest type's rate
    useEffect(() => {
        if (selectedGuestType && selectedGuestType > 0) {

            const filteredGuestType = guestTypes.find(({ guestTypeID }) => guestTypeID === selectedGuestType);
            if (!filteredGuestType) return;

            updateStateValue(FIELD_ID.Rate, filteredGuestType.rateID);
        }
    }, [selectedGuestType, guestTypes]);

    //Populates states associated with the newly selected country
    useEffect(() => {
        if (selectedCountry && selectedCountry > 0) {
            updateStateValue(FIELD_ID.LicenseState, '');
            fetchStates(selectedCountry);
        }
    }, [selectedCountry]);

    //Populates Models associated with the newly selected Make
    useEffect(() => {
        if (selectedVehicleMake && selectedVehicleMake > 0) {
            updateStateValue(FIELD_ID.Model, '');
            fetchModels(selectedVehicleMake);
        }
    }, [selectedVehicleMake]);

    //Updates the Guest Type and Rate with the details in ticket guest type
    useEffect(() => {
        if (!ticketGuestType) return;
        updateStateValue(FIELD_ID.GuestType, ticketGuestType.guestTypeID);
        updateStateValue(FIELD_ID.Rate, ticketGuestType.rateID);
    }, [ticketGuestType]);

    const handleCancel = async () => {
        dispatch(clearValetActivity());
        onCancel();
    };

    const onSubmit = async (data) => {
        try {
            //Setting parkedBy to null as default selected value is not actual and is only for UI purpose.
            data.parkedBy = null;
            let arrivalRequest = {
                entityID: entityID,
                ticketNumber: ticketNumber,
                arrival: data
            }
            await valetParkingService.createValetArrival(arrivalRequest);
            triggerEnqueueSnackBar("Ticket Successfully Arrived", "success");
            handleCancel();
        } catch (error) {
            let errorMsg = "Error occurred while saving arrival details";
            if (error.response?.status === 409) {
                errorMsg = "Ticket already arrived";
            }
            triggerEnqueueSnackBar(errorMsg, "error");
        }
    };

    const renderTextBoxField = (ticketField, formField) => (
        <Controller
            key={formField.fieldID}
            name={formField.fieldStateName}
            control={control}
            render={({ field }) => (
                <TextField
                    {...field}
                    id={formField.fieldStateName}
                    className={clsx(formField.fieldStateName)}
                    label={`${ticketField.fieldName}${ticketField.required ? " *" : ""}`}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={!!errors[formField.fieldStateName]}
                    helperText={errors[formField.fieldStateName]?.message}
                    FormHelperTextProps={{
                        "data-testid": `${formField.fieldStateName}-helpertext`
                    }}
                />
            )}
        />
    );

    const renderMenuItemsByFieldID = (ticketField) => {
        if (!ticketField) return null;

        const dropDownSource = DROPDOWN_SOURCES.find(item => item.fieldID === ticketField.fieldID);
        const items = dropDownSource?.Source || [];

        if (items.length > 0) {
            return items.map(item => (
                <MenuItem key={item[dropDownSource.ValueColumn]} value={item[dropDownSource.ValueColumn]}>
                    {item[dropDownSource.TextColumn]}
                </MenuItem>
            ));
        } else if (dropDownSource?.isDepedentDropdown) {
            return [
                <MenuItem value="" key={dropDownSource.DependentText}>
                    {dropDownSource.DependentText}
                </MenuItem>
            ];
        }

        return [];
    };

    const renderDropdownField = (ticketField, formField) => {
        const menuItems = renderMenuItemsByFieldID(ticketField);
        const hasMenuItems = menuItems && menuItems.length > 0;

        return (
            <Controller
                key={formField.fieldID}
                name={formField.fieldStateName}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        id={formField.fieldStateName}
                        label={`${ticketField.fieldName}${ticketField.required ? " *" : ""}`}
                        fullWidth
                        error={!!errors[formField.fieldStateName]}
                        helperText={errors[formField.fieldStateName]?.message}
                        className={clsx(formField.fieldStateName)}
                        select={hasMenuItems} // Conditionally apply the select prop to avoid warnings when there are no options
                        variant="outlined"
                        margin="normal"
                        SelectProps={{
                            SelectDisplayProps: { "data-testid": `${formField.fieldStateName}-select` },
                        }}
                        FormHelperTextProps={{
                            "data-testid": `${formField.fieldStateName}-helpertext`
                        }}
                    >
                        {menuItems}
                    </TextField>
                )}
            />
        );
    };

    const renderTicketFields = () => (
        arrivalTicketFields?.map(ticketField => {
            const formField = FORM_FIELD_DETAILS.find(field => field.fieldID === ticketField.fieldID);
            if (!formField) return null;
            switch (formField.fieldType) {
                case FIELD_TYPE.TextBox:
                    return renderTextBoxField(ticketField, formField);
                case FIELD_TYPE.DropDown:
                    return renderDropdownField(ticketField, formField);
                default:
                    return null;
            }
        })
    );

    return (
        <div className={classes.step} data-testid="valet-arrival-step">
            <div className={classes.arrivalContent}>
                <Typography
                    variant="h4"
                    component="h1"
                    className={clsx("arrival-header", classes.header)}
                >
                    Arrive Ticket
                </Typography>
                <TextField
                    variant="outlined"
                    margin="normal"
                    id="ticketNumber"
                    label="Ticket Number"
                    defaultValue={ticketNumber}
                    disabled
                    fullWidth
                />
                {renderTicketFields()}
                <div>
                    <Button
                        className={clsx("cancelBtn", classes.buttonRight)}
                        name="cancel"
                        variant="contained"
                        onClick={handleCancel}
                        disabled={isSubmitting}
                        data-testid="cancelBtn"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        name="submit"
                        type="submit"
                        variant="contained"
                        className={clsx("saveBtn", classes.buttonRight)}
                        onClick={handleSubmit(onSubmit)}
                        disabled={isSubmitting}
                        data-testid="saveBtn"
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};

CashieredDevicePerformValetArrivalActivity.defaultProps = {
    classes: {},
    onCancel: () => {},
};

CashieredDevicePerformValetArrivalActivity.propTypes = {
    classes: PropTypes.object,
    onCancel: PropTypes.func,
};

export default CashieredDevicePerformValetArrivalActivity;
