import React, { useCallback, useEffect, useMemo, useState } from "react";

import { FormControlLabel, Grid, Switch, MenuItem, TextField, Tooltip } from "@material-ui/core";

import { useForm, Controller } from "react-hook-form";

import { useTheme } from "@material-ui/core/styles";

import { useStyles } from "../styles";

import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";

import DeviceService from "../../../../services/DeviceService";
import AreaService from "../../../../services/AreaService";
import apiClient from "../../../../auth/apiClient";
import { useConfirmationDialog } from "../../../../hooks/useConfirmationDialog";

const NestDeviceForm = ({
  entityID,
  deviceMode
}) => {
  const deviceService = new DeviceService(apiClient);
  const areaService = new AreaService(apiClient);

  const [areas, setAreas] = useState([]);
  const [defaultDestination, setDefaultDestination] = useState("");
  const [modeOfDevice, setModeOfDevice] = useState("");

  const theme = useTheme();
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { showDialog } = useConfirmationDialog();

  const defaultNestSettings = useMemo(() => ({
    nestDestinationID: "",
    nestDestinationOverride: false,
    nestUpdateIOStatus: true,
  }), []);

  const getNestAreas = useCallback(async () => {
    try {
      const response = await areaService.getNestAreas(entityID);
      return response.data;
    } catch (error) {
      return null;
    }
  }, [entityID]);

  const getSettings = useCallback(async () => {
    try {
      const response = await deviceService.getParkingDeviceNestSettings(entityID);
      return response.data;
    } catch (err) {
      return null;
    }
  }, [entityID]);

  useEffect(() => {
    if (entityID && deviceMode) {
      const process = async () => {
        if (deviceMode?.toLowerCase() !== "entry" && deviceMode?.toLowerCase() !== "exit") {
          // other device cannot be nested (as of now)
          reset(defaultNestSettings);
          await UpdateDeviceNestSettings();
        } else {

          const [areasResponse, settingsResponse] = await Promise.all([
            getNestAreas(),
            getSettings()
          ]);

          const processedAreas = areasResponse
            ? buildAreaList(areasResponse.hierarchy, areasResponse.nestAreas)
            : { areas: [], destination: '' };

          setAreas(processedAreas.areas);
          setDefaultDestination(processedAreas.destination);

          if (settingsResponse) {
            if (modeOfDevice !== "" && deviceMode?.toLowerCase() !== modeOfDevice) {
              reset({
                nestDestinationID: processedAreas.destination,
                nestDestinationOverride: false,
                nestUpdateIOStatus: true,
              });
              await UpdateDeviceNestSettings();

            } else {

              reset({
                nestDestinationID: (settingsResponse?.nestDestinationOverride !== true && processedAreas.destination != "")
                  ? processedAreas.destination
                  : settingsResponse?.nestDestinationID ?? "ExitFacility-NULL",
                nestDestinationOverride: settingsResponse?.nestDestinationOverride,
                nestUpdateIOStatus: settingsResponse?.nestUpdateIOStatus,
              });
            }
          }
        }
        setModeOfDevice(deviceMode?.toLowerCase());
      };

      process();
    }

  }, [entityID, deviceMode, getNestAreas, getSettings])

  const {
    control,
    setValue,
    getValues,
    reset,
    watch
  } = useForm({
    defaultValues: defaultNestSettings,
    mode: 'onChange'
  });

  // Update nest settings
  const UpdateDeviceNestSettings = async () => {
    try {
      let destinationID = getValues("nestDestinationID");
      let override = getValues("nestDestinationOverride");
      destinationID = destinationID === "" || destinationID === "ExitFacility-NULL" || !override
        ? null
        : destinationID;
      await deviceService.updateParkingDeviceNestSettings(
        entityID,
        destinationID,
        override,
        getValues("nestUpdateIOStatus")
      );
    } catch (error) {
      enqueueSnackbar("Failed to update nest settings.", {
        variant: "error",
        tag: "FailedToUpdateNestSettings"
      });
    }
  };

  const handleOverrideChange = async (e) => {
    if (!e.target.checked) {
      setValue("nestDestinationID", defaultDestination);
    }
    await UpdateDeviceNestSettings();
  }

  const handleDestinationChange = async (e) => {
    await UpdateDeviceNestSettings();
  }

  const handleStatusChange = async (e) => {
    var currVal = getValues("nestUpdateIOStatus");
    if (currVal) {
      const response = await showDialog({
        title: `Disable Update I/O Status?`,
        message: `Disabling Update I/O Status will prevent this device from affecting the in/out status of the parker.`,
        buttons: [
          { name: "Yes", color: "primary" },
          { name: "No", color: "secondary" },
        ],
      });
      if (response === "No") return;
    }
    setValue("nestUpdateIOStatus", !currVal);
    await UpdateDeviceNestSettings();
  }

  const disableNest = watch("nestDestinationOverride");

  const buildAreaList = (baseHierarchy, baseAreas) => {
    var defaultReturn = {
      areas: [],
      destination: ''
    };

    if (!baseHierarchy || !baseAreas) {
      return defaultReturn;
    }

    let ptr = baseHierarchy;
    let device = ptr.find(({ entityID }) => entityID === entityID);
    if (device != undefined && device.entityType === 3) {
      let parent = ptr.find(({ entityID }) => entityID === device.parentEntityID);
      if (parent != undefined) {

        if (parent.entityType === 5) {  // parent is an Area
          if (deviceMode?.toLowerCase() === "exit") {
            const grandParent = ptr.find(({ entityID }) => entityID === parent.parentEntityID);

            let areasTmp = [];
            areasTmp.push({
              entityID: parent.parentEntityID,
              name: grandParent != undefined && grandParent.entityName != null
                ? grandParent.entityName.concat(" (Default)")
                : "Facility (Default)"
            })
            areasTmp.push({
              entityID: "ExitFacility-NULL",
              name: "Exit Facility"
            })

            return {
              areas: areasTmp.concat(baseAreas),
              destination: parent.parentEntityID
            };

          } else if (deviceMode?.toLowerCase() === "entry") {

            let areasTmp = [];
            for (let i = 0; i < baseAreas.length; i++) {
              areasTmp.push({
                entityID: baseAreas[i].entityID,
                name: baseAreas[i].name
              })
            }

            let areaParent = areasTmp.find(({ entityID }) => entityID === device.parentEntityID);
            if (areaParent != undefined) {
              areaParent.name = areaParent.name.concat(" (Default)");
            }

            return {
              areas: areasTmp,
              destination: parent.entityID
            };

          } else {
            return defaultReturn;
          }

        } else if (parent.entityType === 2) { // parent is facility

          if (deviceMode?.toLowerCase() === "exit") {

            let areasTmp = [];
            areasTmp.push({
              entityID: "ExitFacility-NULL",
              name: "Exit Facility (Default)"
            })

            return {
              areas: areasTmp.concat(baseAreas),
              destination: "ExitFacility-NULL"
            };

          } else if (deviceMode?.toLowerCase() === "entry") {

            let areasTmp = [];
            areasTmp.push({
              entityID: parent.entityID,
              name: parent.entityName != null
                ? parent.entityName.concat(" (Default)")
                : "Facility (Default)"
            })

            return {
              areas: areasTmp.concat(baseAreas),
              destination: parent.entityID
            };
          } else {
            return defaultReturn;
          }
        }
      }
    }

    return defaultReturn;
  }

  return (
    <>
      {(deviceMode?.toLowerCase() === "exit" || deviceMode?.toLowerCase() === "entry") && (
        <>
          <Grid data-tag="nestUpdateIOStatus" item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Controller
              name="nestUpdateIOStatus"
              control={control}
              render={({ field }) => (
                <Tooltip
                Placement ="right-end"
                data-testid="nest-Update-IOStatus-tooltip"
                title={"When enabled, access holder's I/O status will be updated based on the direction of the lane device.This setting is typically disabled when the nest device is accessed after entering the main facility."}
                >
                <FormControlLabel
                  labelPlacement="end"
                  control={
                    <Switch
                      {...field}
                      name="nestUpdateIOStatus"
                      data-testid="nest-Update-IOStatus"
                      checked={field.value}
                      data-checked={field.value}
                      onChange={(e) => {
                        handleStatusChange(e);
                      }}
                    />
                  }
                  label={
                    <span
                      style={{
                        color:
                          field.value === true
                            ? theme.palette.error.main
                            : theme.palette.text.primary
                      }}
                    >
                      Update I/O Status
                    </span>
                  }
                />
                </Tooltip>
              )}
            />
          </Grid>

          {deviceMode?.toLowerCase() !== "entry" && (
            <Grid container spacing={2} data-tag="nestDestinationOverrideGroup" item xs={12} sm={4} md={4} lg={4} xl={4} >
              <Grid data-tag="nestDestinationOverride" item >
                <Controller
                  name="nestDestinationOverride"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      labelPlacement="end"
                      control={
                        <Switch
                          {...field}
                          name="nestDestinationOverride"
                          data-testid="nestDestinationOverride-testid"
                          checked={field.value}
                          data-checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                            handleOverrideChange(e);
                          }}
                        />
                      }
                      label={
                        <span
                          style={{
                            color:
                              field.value === true
                                ? theme.palette.error.main
                                : theme.palette.text.primary
                          }}
                        >
                          Destination Override
                        </span>
                      }
                    />
                  )}
                />
              </Grid>

              <Grid data-tag="nestDestinationID" item xs={12}>
                <Controller
                  name="nestDestinationID"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      name="nestDestinationID"
                      fullWidth
                      id="nestDestinationID"
                      type="nestDestinationID"
                      data-testid="nestDestinationID-testid"
                      label="Destination"
                      select
                      disabled={!disableNest}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        handleDestinationChange(e);
                      }}
                      InputLabelProps={{ shrink: field.value }}
                    >
                      {areas != null &&
                        areas.map(row => (
                          <MenuItem
                            key={row.entityID}
                            value={row.entityID}
                            id={row.name}
                          >
                            {row.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>
          )}
          <Grid item data-tag="spacer" xs={12} sm={deviceMode?.toLowerCase() === "entry" ? 8 : 4} />

        </>
      )}
    </>
  );
};

export default NestDeviceForm;