import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback
} from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

export const RevenuePollingEnum = {
    SHOW: (duration)=> duration,
    HIDE: 0,
    IGNORE: -1,
};

const revenuePollingFlagContext = createContext();

export const useRevenuePollingFlagContext = () => {
    const context = useContext(revenuePollingFlagContext);
    return context;
}

export const RevenuePollingFlagProvider = ({children}) => {
    const [revenuePollingFlag, setRevenuePollingFlag] = useState(RevenuePollingEnum.IGNORE);
    const [accordianExpanded, setAccordianExpanded] = useState(false);

    const { revenueDashboardPolling } = useFlags();

    useEffect(() =>{
        switch(revenueDashboardPolling){
            case RevenuePollingEnum.HIDE:
                setRevenuePollingFlag(RevenuePollingEnum.HIDE)
                break;
            case RevenuePollingEnum.IGNORE:
                setRevenuePollingFlag(RevenuePollingEnum.IGNORE)
                break;
            default:
                if(revenueDashboardPolling > 0){
                    setRevenuePollingFlag(RevenuePollingEnum.SHOW(revenueDashboardPolling))
                } else{
                    setRevenuePollingFlag(RevenuePollingEnum.HIDE)
                } 
                break;
        }
    },[]);

    const onAccordianClick = () => {
        setAccordianExpanded(prevState => !prevState);
      };

    const hideTimer = useCallback(() => revenuePollingFlag === RevenuePollingEnum.HIDE, [revenuePollingFlag]);

    const showTimer = useCallback(() => revenuePollingFlag !== RevenuePollingEnum.HIDE && revenuePollingFlag !== RevenuePollingEnum.IGNORE , [revenuePollingFlag]);

    return (
        <revenuePollingFlagContext.Provider value={{revenuePollingFlag,accordianExpanded,onAccordianClick, showTimer, hideTimer}}>
            {children}
        </revenuePollingFlagContext.Provider>
    )
}