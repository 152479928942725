import React, { useState, useEffect } from "react";

/* Components */
import StyledPanel from "../StyledPanel";
import ValidationPanelHeader from "./Header";
import ValidationUsersList from "../../List/ValidationUsers";
import UserService from "../../../services/UserService";
import apiClient from "../../../auth/apiClient";
import Pagination from "@material-ui/lab/Pagination";
import { Grid } from "@material-ui/core";

/* State */
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useForm, useFormState } from "react-hook-form";
import { useSelector } from "react-redux";

/* Hooks */
import useValidationAccountUserFunctions from "../../../hooks/ValidationAccounts/useValidationAccountUserFunctions";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import useUserContext from "../../../hooks/useUserContext";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";

/* Style */
import { useStyles } from "./style";
import clsx from "clsx";

/* Reducers */
import { onFilterChange } from "../../../reducers/users/userReducer";

/* Utilities */
import * as _ from "lodash";

/* Constants */
import { USER_TYPES } from "../../../constants";

export default function ValidationUsersPanel({
  className,
  validationAccountID,
}) {
  const getRowsByHeight = (height) => {
    return Math.round(height / 130);
  }
  
  const isIdValid = (id) => {
    return !_.isUndefined(id) && !_.isNaN(id) && !_.isNull(id);
  };
  
  const userService = new UserService(apiClient);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [userList, setUserList] = useState([]);
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState();
  const user = useCurrentUser();
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const [filteredCollectionOfUsers, setFilteredCollectionOfUsers] = useState([]);
  const [selected, setSelected] = useState([]);
  const [addedList, setAddedList] = useState(new Set());
  const [deletedList, setDeletedList] = useState(new Set());
  const [showSystemUsersOnly, setShowSystemUsersOnly] = useState(
    user.UserType != USER_TYPES.ValidationAccount
  );
  const {
    resendInvitation,
    deleteValidationAccountUser,
    updateValidationAccountUsers
  } = useValidationAccountUserFunctions();

  const { setValue, control, handleSubmit, getValues } = useForm({
    defaultValues: {
      addedIDs: [],
      deletedIDs: []
    },
  });
  const { isDirty } = useFormState({ control });
  const { height } = useWindowDimensions();
  const [itemLimit, setItemLimit] = useState(getRowsByHeight(height));
  const { userReducer } = useUserContext();
  const [userData, userDispatch] = userReducer;

  useEffect(() => {
    setValue("selectedIDs", selected);
  }, [JSON.stringify(selected)]);

  const handleSearchChange = (term) => {
    setSearchTerm(term);
  };

  useEffect(() => {
    userDispatch({
      type: onFilterChange,
      payload: { userSearchTerm: searchTerm?.trimLeft()?.trimRight(), userPage: 1 },
    });
  }, [searchTerm])

  const handlePageChange = (e, page) => {
    userDispatch({ type: onFilterChange, payload: { userPage: page } });
  };
  
  useEffect(() => {
    getUsers();
  }, [userData.userPage, itemLimit, userData.userSearchTerm, showSystemUsersOnly, validationAccountID, scopeAwareFacilityID]);

  useEffect(() => {
    getNewLimit();
  }, [height]);

  const getNewLimit = () => {
    if (height && height > 0) {
      setItemLimit(getRowsByHeight(height));
    }
  }

  const handleAccountCheckboxClicked = () => {
    setShowSystemUsersOnly(!showSystemUsersOnly);
  };

  const handleUserCreation = async (user) => {
    setAddedList((prevAddedList) => {
      const newAddedList = new Set(prevAddedList);
      newAddedList.add(user.userId);
      setValue("addedIDs", Array.from(newAddedList), { shouldTouch: true, shouldDirty: true });
      handleSave(getValues());
      return newAddedList;
    });
  }

  const handleSelectedUsersChanged = (userId, checked) => {
    setAddedList((prevAddedList) => {
      const updatedAddedList = new Set(prevAddedList);
      updatedAddedList[checked ? 'add' : 'delete'](userId);
      setValue("addedIDs", Array.from(updatedAddedList), { shouldTouch: true, shouldDirty: true });
      return updatedAddedList;
    });
  
    setDeletedList((prevDeletedList) => {
      const updatedDeletedList = new Set(prevDeletedList);
      updatedDeletedList[!checked ? 'add' : 'delete'](userId);
      setValue("deletedIDs", Array.from(updatedDeletedList), { shouldTouch: true, shouldDirty: true});
      return updatedDeletedList;
    });
  };

  const handleSave = async (values) => {
    const { addedIDs, deletedIDs } = values;
    await updateValidationAccountUsers(addedIDs, deletedIDs, validationAccountID);
    setUserList([]);
    setValue("addedIDs", []);
    setValue("deletedIDs", []);
    await getUsers();
  };

  const handleDelete = async (user) => {
    await deleteValidationAccountUser(user, facilityID, validationAccountID);
    await getUsers();
  };

  const getUsers = async () => {
    if (!isIdValid(validationAccountID)) return;

    try {
      const users = await userService.getAllUsersForValidationAccount(
        scopeAwareFacilityID, 
        validationAccountID, 
        userData.userSearchTerm, 
        showSystemUsersOnly, 
        itemLimit,
        userData.userPage ? (userData.userPage - 1) * itemLimit : 0,
      );

      const toRemoveDeletedVAUsers = [...userList];
      let index = -1;
      if (!showSystemUsersOnly) {
        for (let i = 0; i < toRemoveDeletedVAUsers.length; i++) {
          if (toRemoveDeletedVAUsers[i].userType === 1) {
            if (users.data.collection?.find((u) => { return u.userId === toRemoveDeletedVAUsers[i].userId }) == undefined) {
              index = i;
              break;
            }
          }
        }
      }

      const tmpUserList = index >= 0
        ? toRemoveDeletedVAUsers.toSpliced(index, 1)
        : [...userList];

      const filteredUserList = users.data.collection?.filter((user) => {
        if (!tmpUserList.find((u) => { return u.userId === user.userId })) {
          return user;
        } 
      });
      const newList = tmpUserList.concat(filteredUserList);
      setUserList(newList);
              
      setFilteredCollectionOfUsers(users.data);

      // update collection based on added/deleted lists
      const selectedUsers = newList.filter((user) => {
        if ((user.enabled === true && !deletedList.has(user.userId)) ||
            (!user.enabled && addedList.has(user.userId))) {
              return user;
        }
      });
      const selectedIds = selectedUsers.map((user) => user.userId);
      
      setSelected(selectedIds);
    } catch (err) {
      enqueueSnackbar("Failed to retrieve validation users", {
        variant: "error",
        tag: "fetchValidationUsersError"
      });
    }
  };

  return (
    <StyledPanel
      className={clsx(classes.root, className)}
      headerContent={
        <ValidationPanelHeader
          validationAccountID={validationAccountID}
          onSearchChange={handleSearchChange}
          accountUsersClicked={handleAccountCheckboxClicked}
          onUserCreated={handleUserCreation}
          dirty={isDirty}
          onSave={handleSubmit(async (values) => await handleSave(values))}
        />
      }
      cardContent={
        <>
        <Grid container>
          <ValidationUsersList
            validationAccountId={validationAccountID}
            selected={selected}
            validationUsers={filteredCollectionOfUsers.collection}
            handleResend={resendInvitation}
            onChange={handleSelectedUsersChanged}
            onDelete={handleDelete}
            onSubmit={getUsers}
          />
        <Grid item xs={12} className={ classes.paginationAlign }>
            <Pagination
              className={clsx("pagination")}
              page={userData.userPage ?? 1}
              onChange={handlePageChange}
              count={Math.ceil(filteredCollectionOfUsers.totalCount / itemLimit)}
              color="primary"
              shape="rounded"
              size="small"
              showFirstButton
              showLastButton
            />
        </Grid>
    </Grid>
        </>
      }
    />
  );
}
