import React, { createContext, useEffect, useState, useContext, useReducer } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

/* Utilities */
import useCancellationToken from "../../../../hooks/useCancellationToken";
import moment from "moment";
import useHubContext from "../../../../hooks/useHubContext";
import useCurrentFacilityTimezone from "../../../../hooks/useCurrentFacilityTimezone";

/* Services */
import apiClient from "../../../../auth/apiClient";
import DashboardService from "../../../../services/DashboardService";
import { useFlags } from "launchdarkly-react-client-sdk";

/* Constants */
import { ACTIVITY_TYPES } from "../../../../constants";

export const RevenueWidgetMessageTypes = {
  TicketCount: 0,
  RevenueTimeSeries: 1,
};

const defaultTicketCountState = {
    exited: 0,
    unpaid: 0,
  };
const defaultNetOverallState = [];
const defaultCombinedTotalsState = {};

const facilityDetailsDataReducer = (state, { type, payload }) => {
  let facility;

  switch (type) {
    case "INITIAL_LOAD": 
      return { ...state, 
        facilities: [ ...state.facilities.filter(f => f.facilityID !== payload.facilityID), payload]  
      };
    case "UPDATE_TICKET":
      facility = state.facilities.find(f => f.facilityID === payload.facilityID);
      facility.revenueData.tickets = payload.revenueData.tickets;
      return { ...state, 
        facilities: [ ...state.facilities.filter(f => f.facilityID !== payload.facilityID), facility]  
      };
    case "UPDATE_REVENUE":
      facility = state.facilities.find(f => f.facilityID === payload.facilityID);
      facility.revenueData.combinedTotals = payload.revenueData.combinedTotals;
      facility.revenueData.netOverall = payload.revenueData.netOverall;
      facility.revenueData.netToday = payload.revenueData.netToday;
      return { ...state, 
        facilities: [ ...state.facilities.filter(f => f.facilityID !== payload.facilityID), facility]  
      };
    default:
      return state;
  }
};

export const RevenueDataContext = createContext();

const RevenueDataProvider = props => {
    const [revenueByFacility, setRevenueByFacility] = useState([]);
    const [totalRevenue, setTotalRevenue] = useState(0);
    const { convertUtcDate } = useCurrentFacilityTimezone();
    const facilities = useSelector((state) => state.entityScope.selected) ?? [];
    const [dataLoaded, setDataLoaded] = useState(false);
    const entityIDsRaw = useSelector((state) => state?.entities?.EntityIDsRaw);
    const {revenueDashboardPolling} = useFlags();

    const [state, dispatch] = useReducer(
      facilityDetailsDataReducer,
      { facilities: [] }
    );
    const dashboardService = new DashboardService(apiClient);

    const currentDayUTC = convertUtcDate(moment.now())
      .startOf("day")
      .format();

    const sevenDaysAgoUTC = convertUtcDate(moment.now())
      .startOf("day")
      .subtract(7, "d")
      .format();
      
    const startOfMonthUTC = convertUtcDate(moment.now())
      .startOf("month")
      .format();

    const loadRevenueData = async (facilities) => {
      getTotalsData(facilities);
      setDataLoaded(true);
    }

    const getTotalsData = async (facilities) => {
      if (!facilities || facilities.length <= 0) { return; }
      
      let entityIds = Array.prototype.map.call(facilities, function(f) { return f.id }).join(',');
      try {
        let response = await dashboardService.GetRevenueForFacilities(
          entityIds,
          currentDayUTC);

        if (response.data) {
          setRevenueByFacility(response.data.revenueByFacility);
          setTotalRevenue(response.data.totalRevenue);
        }
      } catch (error) {
        setRevenueByFacility([]);
      }
    }

    /* 
    facilityDetailsData will hold:
        netToday,
        netOverall,
        tickets,
        combinedTotals,
        dataLoaded
    */ 
   
    const { portalHub, Connected: PortalHubConnected } = useHubContext();
    const { execute: executeNetRevenueTodayQuery } = useCancellationToken({
      func: getNetRevenueToday,
    });

    const {
      execute: executeRevenueTimeSeriesQuery,
      inProgress: loadingDetails,
    } = useCancellationToken({
      func: getRevenueTimeSeriesData,
    });

    async function getRevenueDataForFacility (facilityID) {
      let currState = state?.facilities?.find(x => x.facilityID === facilityID);
      if (currState && currState.revenueData.dataLoaded) { return; }

      const data = {};
      
      executeNetRevenueTodayQuery({facilityID, data});
      executeRevenueTimeSeriesQuery({facilityID, data});
      data.dataLoaded = true;
      
      setDataLoaded(true);
      dispatch({ 
        type: "INITIAL_LOAD", payload: { facilityID: facilityID, revenueData: data }
      });
    }

    useEffect(() => {
      if (!PortalHubConnected || !facilities) return;
        portalHub.subscribe(`REVENUE_WIDGET`, async (message) => {
          const data = JSON.parse(message);
          const parentEntityID = entityIDsRaw.find(x => x.entityid === data.EntityID)?.parententityid;
          
          if (!parentEntityID || facilities.filter(x => x.id === parentEntityID).length == 0) return;
          
          if(revenueDashboardPolling && revenueDashboardPolling < 0)
            getTotalsData(facilities);
          
          // skip facility details update if they haven't been loaded yet
          if (!state.facilities || state.facilities.filter(x => x.facilityID === parentEntityID).length == 0) return;

          const messageContent = JSON.parse(data.Message);
          switch (messageContent.type) {
            case RevenueWidgetMessageTypes.TicketCount:
              await handleTicketCountChange(parentEntityID, messageContent);
              break;
            case RevenueWidgetMessageTypes.RevenueTimeSeries:
              await handleRevenueChange(parentEntityID, messageContent);
              break;
          }
        });
      
      return () => {
        portalHub.unsubscribe(`REVENUE_WIDGET`);
      };
    }, [PortalHubConnected, facilities, dataLoaded, state, revenueDashboardPolling]);

    const handleTicketCountChange = async (facilityID, content) => {
      let revenueData = state.facilities.find(x => x.facilityID === facilityID)?.revenueData; 

      if (content.activity.toLowerCase() === ACTIVITY_TYPES.Exit.toLowerCase()) {
        revenueData.tickets.unpaid -= 1;
        revenueData.tickets.exited += 1;
      } else if (
          content.activity.toLowerCase() === ACTIVITY_TYPES.Enter.toLowerCase()
      ) {
        revenueData.tickets.unpaid += 1;
      }

      dispatch({ 
        type: "UPDATE_TICKET", payload: { facilityID: facilityID, revenueData: revenueData }
      });
    };
    
    const handleRevenueChange = async (facilityID, content) => {
      let revenueData = state.facilities.find(x => x.facilityID === facilityID)?.revenueData; 

      revenueData.netToday += content.net;
      revenueData.combinedTotals.netDailyTotal += content.net;
      revenueData.combinedTotals.netMonthlyTotal += content.net;
      revenueData.combinedTotals.grossDailyTotal += content.net;
      revenueData.combinedTotals.grossMonthlyTotal += content.net;

      let discountAmount = 0;
      if (content.validations) {
        content.validations.forEach((validation) => {
          discountAmount += Math.abs(validation.AmountApplied) || 0;
        });
      }

      const currentIndex = revenueData.netOverall.findIndex((x) =>
        moment(x.date).isSame(moment.utc(content.time).local(), "day")
      );

      if (currentIndex >= 0) {
        let newOverall = revenueData.netOverall;
        newOverall[currentIndex].net += content.net;
        newOverall[currentIndex].gross += content.gross;
        revenueData.netOverall = newOverall;
      } else {
        revenueData.netOverall[0].date = moment.utc(content.time).local();
        revenueData.netOverall[0].net = content.net;
        revenueData.netOverall[0].gross = content.gross;
      }

      dispatch({ 
        type: "UPDATE_REVENUE", payload: { facilityID: facilityID, revenueData: revenueData }
      });
    };

    async function getNetRevenueToday({ facilityID, data, cancelToken }) {
      const currentUTC = convertUtcDate(moment.now())
        .startOf("day")
        .format();
      const response = await dashboardService.GetNetRevenueForDay({
        entityID: facilityID,
        date: currentUTC,
        cancelToken,
      });

      data.netToday = response.data.net;
    }
    
    async function getRevenueTimeSeriesData({ facilityID, data, cancelToken }) {
      const response = await dashboardService.GetRevenueForDateRange({
        entityID: facilityID,
        startOfMonthUTC,
        currentDayUTC,
        sevenDaysAgoUTC,
        cancelToken,
      });

      data.netOverall = response.data.revenue ?? defaultNetOverallState;
      data.tickets = response.data.tickets ?? defaultTicketCountState;
      data.combinedTotals = response.data.totals ?? defaultCombinedTotalsState;
    }

    const facilityDetailsData = () => { return state; }

  return (
    <RevenueDataContext.Provider
    value={{
      revenueByFacility,
      facilities,
      totalRevenue,
      getTotalsData,
      loadRevenueData,
      getRevenueDataForFacility,
      loadingDetails,
      facilityDetailsData
    }}
    >
      {props.children}
    </RevenueDataContext.Provider>
  )
};

export default RevenueDataProvider;

export const useRevenueDataContext =  () => {
  const context = useContext(RevenueDataContext);
  return context;
}