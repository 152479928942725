import React from "react";
import PropTypes from "prop-types";

/* Routing */
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";

/* Utilities */
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { useTransientCountV2 } from "../../../hooks/useTransientCount";
import moment from "moment";
import { useGate } from "../../../hooks/useGate";

/* State */
import { useDispatch, useSelector } from "react-redux";
import { clearTransaction, endCashierShift } from "../../../state/slices/shiftSession/shiftSession";

/* Constants */
import { CREDENTIAL_TYPES, CASHIER_DEVICE, TICKET_FIELD_REQUEST_TYPE } from "../../../constants";

/* Components */
import CashieredDeviceSelectEntryType from "../../../components/CashierDevice/SelectEntryType";
import CashieredDeviceScanCredential from "../../../components/CashierDevice/ScanCredential";
import CashieredDeviceScanTicket from "../../../components/CashierDevice/ScanTicket";
import CashieredDeviceSummary from "../../../components/CashierDevice/Summary";
import CashieredDeviceSelectOffer from "../../../components/CashierDevice/SelectOffer";
import CashieredDeviceChoosePaymentType from "../../../components/CashierDevice/ChoosePaymentType";
import CashieredDevicePayCash from "../../../components/CashierDevice/PayCash";
import PayCredit from "../../../components/CashierDevice/PayCredit";
import CashieredDeviceScanDiscounts from "../../../components/CashierDevice/ScanDiscounts";
import CashieredDeviceAdjustFee from "../../../components/CashierDevice/AdjustFee";
import usePos from "../../../components/CashierDevice/Utilities/usePos";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useActivity from "../../../hooks/useActivity";
import useQueryParams from "../../../hooks/useQueryParams";
import CashieredDevicePerformValetArrivalActivity from "../../../components/CashierDevice/PerformValetArrivalActivity";

import {
  Zoom,
} from "@material-ui/core";

/* Services */
import CashierShiftsService from "../../../services/CashierShiftsService";
import apiClient from "../../../auth/apiClient";

const cashierShiftsService = new CashierShiftsService(apiClient);

const Steps = ({ classes, onNavigation }) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const enqueueSnackbar = useEnqueueSnackbar();
  const notificationStyle = useSelector((state) => {
    return {
      small: state.shiftSession.smallScreen,
      toastLocation: state.shiftSession.toastLocation,
    };
  });
  const { recordActivity } = useActivity();
  const cashieredDeviceID = useSelector(
    (state) => state.shiftSession?.cashieredDeviceID
  );

  const shiftId = useSelector(
    (state) => state.shiftSession?.shiftID
  );

  const endShiftAfterTransaction = useSelector(
    (state) => state.shiftSession?.endShiftAfterTransaction
  );


  const { adjustTransientCount, adjustTransientCountForRoaming } = useTransientCountV2(cashieredDeviceID);
  const { sendGateAction } = useGate(cashieredDeviceID);
  const validatedCredential = useSelector(
    (state) => state.shiftSession.transaction.credential
  );
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const { cancelTransaction } = usePos({ cashierID: currentUser.UserID });
  const queryParams = useQueryParams();

  const vendGateAndGoToSummary = () => {
    sendGateAction("vend");
    adjustTransientCount(
      validatedCredential?.credentialType === CREDENTIAL_TYPES.PREPAID
        ? CREDENTIAL_TYPES.PREPAID
        : CREDENTIAL_TYPES.TICKET
    );
    onNavigation("summary");
  };

  const cashieredShiftID = useSelector(
    (state) => state.shiftSession?.shiftID
  );

  const cashieredShiftFacilityID = useSelector(
    (state) => state.shiftSession?.entityID
  );

  const handleCredentialValidated = async (data) => {
    if (data.prepaid === true) {
      processCredentialResult(data);
    } else if (data.zeroFee) {
      enqueueSnackbar("This ticket is already paid - proceed to exit.", {
        variant: "info",
        tag: "statusPaid",
        title: `TicketID ${data.credential.ticketID.slice(-12)}`,
        anchorOrigin: notificationStyle.toastLocation,
        fullwidth: notificationStyle.small,
      });
      sendGateAction("vend");
      
      if (endShiftAfterTransaction) {
        endShift();
        history.push("/shiftSession/cashieredDevice");
      } else {
        onNavigation("start");
      }

      dispatch({ type: clearTransaction });
    } else {
      onNavigation("payment");
    }
  };

  const handleValetTicketNumberValidated = async () => {
    let activityType = parseInt(queryParams["activityType"] ?? 0);

    switch (activityType) {
      case TICKET_FIELD_REQUEST_TYPE.ARRIVAL:
        onNavigation("valetArrival");
        break;
    }

  };

  const processCredentialResult = async (data) => {
    const attended = (cashieredDeviceID && cashieredDeviceID !== "-1");
    let entityID = attended
      ? cashieredDeviceID
      : data.credential.shiftEntityID;    
    
    let title = `Prepaid Pass ID: ${data.credential.reference}`;
    let countType = CREDENTIAL_TYPES.PREPAID;
    let activity = {
      credentialID: data.credential.credentialID,
      deviceID: cashieredDeviceID,
      activityDate: moment.utc(new Date()),
      activityType: "Enter",
      resultCode: "ValidAccess",
      accessGroupID: data.credential.accessGroupID,
      entityID: entityID
    };

    if (data.credential.credentialType.toLowerCase() === "ticketmaster") {
      title = `Credential ID: ${data.credential.secondaryCredential}`;
      countType = CREDENTIAL_TYPES.TICKETMASTER;
      activity.ticketmasterInfo = {
        secondaryCredential: data.credential.secondaryCredential,
        shiftID: cashieredShiftID
      };
    } else if (data.credential.credentialType.toLowerCase() === "prepaid") {
      title = `Prepaid Pass ID: ${data.credential.reference}`;
      countType = CREDENTIAL_TYPES.PREPAID;
      activity.prepaidInfo = {
        shiftID: cashieredShiftID
      };
    } else {
      title = `Credential ID: ${data.credential.reference}`;
      countType = CREDENTIAL_TYPES.BARCODE;
    }

    enqueueSnackbar("Access Granted", {
      variant: "success",
      tag: "accessGranted",
      title: title,
      anchorOrigin: notificationStyle.toastLocation,
      fullwidth: notificationStyle.small,
    });
    await recordActivity(activity);
    if (attended) {
      adjustTransientCount(countType);
    } else {
      adjustTransientCountForRoaming(cashieredShiftFacilityID, countType);
    }
    sendGateAction("vend");
    
    if (endShiftAfterTransaction) {
      endShift();
      history.push("/shiftSession/cashieredDevice");
    } else {
      onNavigation("start");
    }

    dispatch({ type: clearTransaction });   
  }

  const handleInitialFeeCalculation = async (fee) => {
    if (fee === 0) {
      enqueueSnackbar("This ticket is already paid - proceed to exit.", {
        variant: "info",
        tag: "statusPaid",
        anchorOrigin: notificationStyle.toastLocation,
        fullwidth: notificationStyle.small,
      });
      await cancelTransaction();
      adjustTransientCount(CREDENTIAL_TYPES.TICKET);
      sendGateAction("vend");

      if (endShiftAfterTransaction) {
        endShift();
        history.push("/shiftSession/cashieredDevice");
      } else {
        onNavigation("start"); 
      }
        

      dispatch({ type: clearTransaction });
    }
  };

  const endShift = async () => {
    try {
      const endShiftResult = await cashierShiftsService.endShift(shiftId, currentUser.UserID);
      if (endShiftResult?.status === 204) {
        if (!currentUser?.isAdmin === true) {
          history.push("/logout");
        }
        dispatch(endCashierShift());
      } else {
        enqueueSnackbar(`Failed to end shift: `, {
          variant: "error",
          TransitionComponent: Zoom,
          tag: "endShiftFailed",
        });
      }
    } catch (e) {
      enqueueSnackbar(`Failed to end shift: ${e}`, {
        variant: "error",
        TransitionComponent: Zoom,
        tag: "endShiftFailed",
      });
    }
  }

  return (
    <Switch>
      <Route path={`${path}/start`}>
        <CashieredDeviceSelectEntryType
          classes={classes}
          onSelectOfferClick={() => onNavigation("selectOffer")}
          onScanCredentialClick={() => onNavigation("scanCredential")}
          onScanTicketClick={(activityType) => onNavigation(`scanTicket?activityType=${activityType}`)}
          isValetHandheldSelected = {cashieredDeviceID === CASHIER_DEVICE.ValetHandheldCashier}
        />
      </Route>
      <Route path={`${path}/scanCredential`}>
        <CashieredDeviceScanCredential
          classes={classes}
          onCredentialValidated={handleCredentialValidated}
        />
      </Route>
      <Route path={`${path}/scanTicket`}>
        <CashieredDeviceScanTicket
          classes={classes}
          onTicketNumberValidated={handleValetTicketNumberValidated}
        />
      </Route>
      <Route path={`${path}/selectOffer`}>
        <CashieredDeviceSelectOffer
          classes={classes}
          onOfferSelected={() => onNavigation("payment")}
        />
      </Route>
      <Route path={`${path}/payment`}>
        <CashieredDeviceChoosePaymentType
          classes={classes}
          onCancel={() => {
            if (endShiftAfterTransaction) {
              endShift();
              history.push("/shiftSession/cashieredDevice");
            } else {
              onNavigation("start");
            }
          }}
          onDiscounts={() => onNavigation("scanDiscounts")}
          onAdjustFee={() => onNavigation("adjustFee")}
          onPayCash={() => onNavigation("payCash")}
          onPayCredit={() => onNavigation("payCredit")}
          onInitialFeeCalculation={handleInitialFeeCalculation}
        />
      </Route>
      <Route path={`${path}/scanDiscounts`}>
        <CashieredDeviceScanDiscounts
          classes={classes}
          onPaymentBypass={vendGateAndGoToSummary}
        />
      </Route>
      <Route path={`${path}/adjustFee`}>
        <CashieredDeviceAdjustFee
          classes={classes}
          onAdjustFeeComplete={() => onNavigation("payment")}
        />
      </Route>
      <Route path={`${path}/payCredit`}>
        <PayCredit
          classes={classes}
          onComplete={vendGateAndGoToSummary}
          onFailedTransaction={() => onNavigation("payment")}
          onInterrupt={() => onNavigation("payment")}
        />
      </Route>
      <Route path={`${path}/payCash`}>
        <CashieredDevicePayCash
          classes={classes}
          onComplete={vendGateAndGoToSummary}
        />
      </Route>
      <Route path={`${path}/summary`}>
        <CashieredDeviceSummary
          classes={classes}
          onComplete={() => {
            if (endShiftAfterTransaction) {
              endShift();
              history.push("/shiftSession/cashieredDevice");
            } else {
              onNavigation("start");
            }
              
          }}
        />        
      </Route>
      <Route path={`${path}/valetArrival`}>
        <CashieredDevicePerformValetArrivalActivity
          classes={classes}
          onCancel={() => onNavigation("start")}
        />
      </Route>
    </Switch>
  );
};

Steps.defaultProps = {
  onNavigation: () => {},
};

Steps.propTypes = {
  onNavigation: PropTypes.func,
};

export default Steps;
