import React, { useCallback, useEffect, useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useStyles } from "./MultiSearch.style";
import _ from "lodash";
import { shallowEqual, useSelector } from "react-redux";
import useCurrentFacility from "../../../../hooks/useCurrentFacility";
import { Button, FormControl, InputLabel, LinearProgress, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";

import apiClient from "../../../../auth/apiClient";
import SearchService from "../../../../services/SearchService";
import Results from "./Results/Results.index";
const searchService = new SearchService(apiClient)

const SearchCategory = {
  Name: 1,
  LicensePlate: 2,
  ContractCredential: 3,
  Ticket: 4,
}

const MultiSearch = ({contextEntityId}) => {

  const classes = useStyles();

  const searchSchema = Yup.object().shape({
    searchTerm: Yup.string().required().min(3),
    searchCategory: Yup.number().required()
  });

  const defaultValues = {
    searchTerm: "",
    searchCategory: SearchCategory.Name
  };

  const formProvider = useForm({
    resolver: yupResolver(searchSchema),
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, isValid }
   } = formProvider;

   const [searchTerm, setSearchTerm] = useState(defaultValues.searchTerm);
   const [initialSearchFired, setInitialSearchFired] = useState(false);
   const [searchResults, setSearchResults] = useState([]);

   function getPropName(obj, value) {
    for (const key in obj) {
      if (obj[key] === value) {
        return key;
      }
    }
    return "Unknown";
  }
  const [scopeEntityIDs, setScopeEntityIDs] = useState([]);
  const scopeList = useSelector(
    (state) => state.entityScope?.selected, shallowEqual
    ) ?? [];
  
  useEffect(() => {
    var entityIds = scopeList?.map((entity) => { return entity.id; });
    setScopeEntityIDs(entityIds);
  }, [scopeList]);

  const executeSearch = useCallback(async (values) => {
    if (!isValid) {
      return;
    }

    setSearchResults(null);    
    const response =  await searchService.getSearchResultsV2(contextEntityId,
      scopeEntityIDs, values.searchTerm, values.searchCategory);

    if (!_.isNil(response) && response.status === 200 && !_.isNil(response.data)) {
      setSearchTerm(values.searchTerm);
      setSearchResults(response.data);        
    }
    setInitialSearchFired(true);
  }, [contextEntityId, scopeEntityIDs, isValid]);

  const hasNoSearchResults = useCallback(() => {
    return _.isNil(searchResults) ||
      (_.isEmpty(searchResults.accessHolders)
      && _.isEmpty(searchResults.credentials)
      && _.isEmpty(searchResults.tickets))
  }, [searchResults]);

  useEffect(() => {
    // the search drawer is not unmounted, reset default values if selected scopes changed
    setInitialSearchFired(false);
    setSearchResults([]);
    reset();
    
  }, [scopeEntityIDs]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h3" color="primary">Search</Typography>
        <FormProvider {...formProvider}>
          <form onSubmit={handleSubmit(executeSearch)}>
            <div className={classes.searchContainer}>
              <Controller
                name="searchTerm"
                control={control}
                render={({ field }) => (
                  <TextField {...field}
                    label="Search for..."
                    id="omnisearch-search-term"
                    variant="outlined"
                    className={clsx("search-field", classes.searchTerm)}
                    helperText="minimum 3 characters"/>
                )}
              />
              <FormControl variant="outlined">
                <InputLabel id="omnisearch-search-category-label">Category</InputLabel>
                <Controller
                  name="searchCategory"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}
                      labelId="omnisearch-search-category-label"
                      id="omnisearch-search-category"
                      label="Category"
                      classes={{ select: classes.select }}
                      inputProps={{
                        "data-id": "search-category-select",
                        "data-name": getPropName(SearchCategory, field.value)
                      }}>
                      <MenuItem value={SearchCategory.Name} data-name={getPropName(SearchCategory, SearchCategory.Name)}>Name</MenuItem>
                      <MenuItem value={SearchCategory.LicensePlate} data-name={getPropName(SearchCategory, SearchCategory.LicensePlate)}>License Plate</MenuItem>
                      <MenuItem value={SearchCategory.ContractCredential} data-name={getPropName(SearchCategory, SearchCategory.ContractCredential)}>Contract</MenuItem>
                      <MenuItem value={SearchCategory.Ticket} data-name={getPropName(SearchCategory, SearchCategory.Ticket)}>Ticket</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </div>
            <div className={classes.resultsHeader}>
              <Typography variant="h5" color="primary" className={classes.resultsHeaderText}>
                {initialSearchFired ? "Results" : ""}
              </Typography>
              <Button
              type="submit"
              className={clsx("search-btn-submit", classes.submitBtn)}
              variant="contained"
              color="primary"
              disabled={isSubmitting || !isValid}>
                { isSubmitting ? "Searching" : "Search"}
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
      
      <div className={classes.results}>
        {hasNoSearchResults() 
          ? isSubmitting 
            ? <LinearProgress color="primary" className={classes.searchIcon}/>
            : initialSearchFired 
              ? "No Results"
              : ""
          : <Results searchResults={searchResults} searchTerm={searchTerm} />
        }
      </div>
    </div>
  );
}

export default React.memo(MultiSearch);