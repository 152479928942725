import { useEffect } from "react";
import { RevenuePollingEnum as pollingEum } from "../useRevenueDashboardPollingContext";


export const useRevenuePollingTimer = (accordianExpanded,getTotalsData,facilities,revenuePollingFlag,loadingDetails) => {

    useEffect(() => {
        console.log("accordianExpanded",accordianExpanded)
        console.log("facilities", facilities)
        // If the accordion is not open, reset countdown and do nothing else
        if (!accordianExpanded || loadingDetails) {
          return;
        }
        console.log("revenuepollingflag", revenuePollingFlag)
        if(revenuePollingFlag == pollingEum.HIDE || revenuePollingFlag == pollingEum.IGNORE) return;
        
        const intervalId = setInterval(() => {
          getTotalsData(facilities);
        }, revenuePollingFlag * 1000);
      
        // Cleanup function to clear the intervals when the component unmounts or when accordion closes
        return () => {
          clearInterval(intervalId);
        };
      }, [accordianExpanded, loadingDetails, revenuePollingFlag, facilities]);
}